import React from "react";
import { DefaultSeo } from "next-seo";
import { DEFAULT_SEO } from "@constants";
import { t } from "@lingui/macro";

const DefaultHead = () => {
  const SEO = {
    ...DEFAULT_SEO,
    description: t({
      id: "default.seo.description",
      message:
        "Connect with Local Tour Guides and Enjoy Private Tours from All over the World. Request an Itinerary and Customize Your Tour!",
    }),
  };

  return <DefaultSeo {...SEO}></DefaultSeo>;
};

export default DefaultHead;
